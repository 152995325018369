<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between ">
        <div class="row justify-between" style="width:45%">
          <q-select
            class="self-end"
            use-input
            @filter="filterFn"
            :options="filteredListKelas"
            v-model="selKelas"
            @input="getSiswaList"
            outlined
            label="Kelas"
            style="width:45%"
          ></q-select>
          <q-input
            outlined
            label="Cari Siswa/NISN"
            v-model="searchTerm"
            style="width:45%"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>

        <!-- @filter="filterFn"
          :options="filteredListKelas"
          v-model="selKelas"
          @input="getSiswaKelasList()" -->
        <div class="row justify-between" style="width:45%">
          <q-input
            outlined
            readonly
            stack-label
            v-model="bundleEkskul.label"
            label="Ekskul"
          ></q-input>
          <q-input
            outlined
            label="Cari Siswa/NISN"
            v-model="searchTerm2"
            style="width:45%"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
      </q-card-section>
      <q-card-section class="row justify-between items-center">
        <!-- kiri  -->
        <q-markup-table
          flat
          bordered
          dense
          separator="horizontal"
          style="width:45%"
          class="stickyTable bg-grey-4"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th style="width:20px;">
                <q-checkbox
                  :disable="selKelas == ''"
                  @input="checkingAll('kiri')"
                  v-model="checkAllKiri"
                ></q-checkbox>
              </th>
              <th>no</th>
              <th>nisn</th>
              <th>nama</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-for="(val, i) in filSiswa" :key="i">
              <td>
                <q-checkbox v-model="val.check"></q-checkbox>
              </td>
              <td>{{ i + 1 }}</td>
              <td>{{ val.nisn }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
        <div class="column q-gutter-sm">
          <q-btn
            outline
            @click="(isChanged = true), toGlobal()"
            color="black"
            icon="keyboard_arrow_left"
            :disable="listKanan.length == 0"
          ></q-btn>
          <!-- 
             -->
          <q-btn
            outline
            color="black"
            @click="(isChanged = true), toEkskul()"
            icon="keyboard_arrow_right"
            :disable="listKiri.length == 0"
          ></q-btn>
        </div>
        <!-- kanan  -->
        <q-markup-table
          flat
          bordered
          dense
          separator="horizontal"
          style="width:45%"
          class="stickyTable bg-grey-4"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th style="width:20px;">
                <q-checkbox
                  @input="checkingAll('kanan')"
                  v-model="checkAllKanan"
                ></q-checkbox>
              </th>
              <th>no</th>
              <th>nisn</th>
              <th>nama</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-for="(val, i) in filMember" :key="i">
              <td>
                <q-checkbox v-model="val.check"></q-checkbox>
              </td>
              <td>{{ i + 1 }}</td>
              <td>{{ val.nisn }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>

      <q-card-actions align="left">
        <q-btn
          flat
          color="primary"
          label="back"
          @click="$router.go(-1)"
        ></q-btn>
      </q-card-actions>
    </q-card>
    <q-dialog v-model="show" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <p>Apa anda yakin ingin menyimpan perubahan?</p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Ya" color="primary" v-close-popup />
          <q-btn flat label="Tidak" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      user: {},
      id_container: [],

      bundleEkskul: {
        label: "",
        value: "",
      },

      searchTerm: "",
      searchTerm2: "",

      show: false,
      isChanged: false,
      checkAllKanan: false,
      checkAllKiri: false,

      listKelas: [],
      filteredListKelas: [],
      selKelas: "",

      listKiri: [],
      listKanan: [],
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.listKiri;
      else {
        return this.listKiri.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
    filMember() {
      if (this.searchTerm2 == "") return this.listKanan;
      else {
        return this.listKanan.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm2.toLowerCase()) !=
            -1;
          let cNISN = val.nisn.indexOf(this.searchTerm2) != -1;
          return cNama || cNISN;
        });
      }
    },
  },
  async mounted() {
    await this.getUser();
    await this.refreshData();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async refreshData() {
      this.bundleEkskul.label = this.$route.params.nama;
      this.bundleEkskul.value = this.$route.params.id;

      await this.getListRombel();
    },
    async getListMember() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          `/pengaturan/ekskul/getmember/${
            this.bundleEkskul.value
          }/${localStorage.getItem("id_tahun_ajaran")}/${this.selKelas.value}`
        )
        .then((resp) => {
          for (let i of resp.data) {
            i.check = false;
          }
          this.listKanan = resp.data;
        });
      this.$q.loading.hide();
    },
    async getListRombel() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          `/pengaturan/ekskul/getlistrombel/${localStorage.getItem(
            "jenjang"
          )}/${this.user.id}/${this.user.is_pembina_ekskul}/${
            this.user.is_super_user
          }`
        )
        .then((resp) => {
          this.listKelas = resp.data;
        });
      this.$q.loading.hide();
    },
    async getSiswaList() {
      this.$q.loading.show({ message: "Memuat Data siswa..." });
      await this.$http
        .get("/pengaturan/ekskul/getsiswa/" + this.selKelas.value)
        .then((resp) => {
          for (let i of resp.data) {
            i.check = false;
          }
          this.listKiri = resp.data;
        });
      await this.getListMember();
      this.$q.loading.hide();
    },
    async toEkskul() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let temp = [];
      for (let item of this.listKiri) {
        if (item.check) {
          temp.push(item.id);
        }
      }
      await this.$http.post(
        `/pengaturan/ekskul/postmember/${
          this.bundleEkskul.value
        }/${localStorage.getItem("id_tahun_ajaran")}`,
        temp
      );

      this.$q.loading.hide();
      await this.refreshData();
      await this.getListMember();
      this.currentchecker(temp);
    },
    async toGlobal() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let temp = [];
      for (let item of this.listKanan) {
        if (item.check) {
          temp.push(item.id_siswa);
        }
      }
      await this.$http.put(
        `/pengaturan/ekskul/pullmember/${
          this.bundleEkskul.value
        }/${localStorage.getItem("id_tahun_ajaran")}`,
        temp
      );

      this.$q.loading.hide();
      await this.refreshData();
      await this.getListMember();
      this.currentchecker(temp);
    },
    currentchecker(temp) {
      for (let item of this.listKiri) {
        for (let id of temp) {
          if (item.id == id) {
            item.check = true;
          }
        }
      }
      for (let item of this.listKanan) {
        for (let id of temp) {
          if (item.id_siswa == id) {
            item.check = true;
          }
        }
      }
    },
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.filteredListKelas = this.listKelas;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredListKelas = this.listKelas.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    checkingAll(pos) {
      if (pos == "kanan") {
        if (this.checkAllKanan) {
          for (let item in this.listKanan) {
            this.listKanan[item].check = true;
          }
        } else {
          for (let item in this.listKanan) {
            this.listKanan[item].check = false;
          }
        }
      } else if (pos == "kiri") {
        this.$q.loading.show({ message: "please wait" });
        if (this.checkAllKiri) {
          for (let item in this.listKiri) {
            this.listKiri[item].check = true;
          }
        } else {
          for (let item in this.listKiri) {
            this.listKiri[item].check = false;
          }
        }
        this.$q.loading.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
